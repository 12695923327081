import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import NotFound from '../layouts/404';
export const _frontmatter = {
  "sort": 0,
  "title": "Not Found"
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = NotFound;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`We could not find the resource you were looking for.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      